import React from 'react';
import { Link } from 'gatsby';
import { DateTime } from 'luxon';

import Logo from '../images/logo.png';
import LogoAprilFools from '../images/logo GOOD.png';


export default function Sidebar({ children }){

    const year = new Date().getFullYear();

    return (
        <div className="flex flex-col dark:text-white dark-mode:bg-gray-800">    
            <header className="flex flex-col mx-auto py-4">           
                <Link to="/"><img src={DateTime.now().month === 4 && DateTime.now().day === 1 ? LogoAprilFools : Logo} className="pb-4 h-32" alt="Video Games Are Bad Logo"/></Link>
                <span className="text-center text-2xl"><Link to="/posts">POSTS</Link> <span className="text-pink">|</span> <Link to="/videos">VIDEOS</Link></span>
            </header>
            <section className="flex flex-col mx-auto dark:bg-gray-800 sm:px-0 px-2">
                {children}
            </section>
            <footer className="flex flex-col contre-content mx-auto pb-4 pt-2">
                <span className="text-center">{year} <span className="text-green">|</span> <Link to="/about">About</Link> <span className="text-green">|</span> <Link to="/findme">Find Me</Link></span>
            </footer>
        </div>
    )
}